import { API } from 'api/API';
import { IUserAuthData } from 'api/models/IAuth';
import { create } from 'zustand';

interface IAuthState {
    user: IUserAuthData | null;
    setUser: (user: IUserAuthData | null) => void;
    logout: () => Promise<void>; //
}

export const useAuthStore = create<IAuthState>((set) => ({
    user: null,
    setUser: (user: IUserAuthData | null) => set(() => ({ user })),
    logout: async () => {
        try {
            await API.post('auth/logout');
            set({ user: null });
        } catch (error) {
            set({ user: null });
        }
    },
}));
