import axios from 'axios';
import Utils from 'helpers/Utils';
import { redirect } from 'react-router-dom';

export const API = axios.create({
    baseURL: Utils.Vars.HOST_API,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

API.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            redirect('/login');
        }
        return Promise.reject(error);
    }
);
