class Vars {
    HOST_ENV = import.meta.env.VITE_HOST;
    HOST_UPLOADS = import.meta.env.VITE_HOST_UPLOADS;
    HOST_API = import.meta.env.VITE_HOST_API;

    userGroups = {
        admin: 'Администратор',
        realtor: 'Риэлтор',
        leader: 'Руководитель отдела',
        nop: 'Начальник отдела',
        price: 'Прайс-менеджер',
        content: 'Контент-менеджер',
        seo: 'Seo-оптимизатор',
        ceo: 'Директор',
        commercial: 'Коммерческий директор',
        photographer: 'Фотограф',
        secretary: 'Секретарь',
        designer: 'Дизайнер',
        call: 'Колл-центр',
        callleader: 'Старший колл-центр',
        lawyer: 'Юрист',
        lawyersenior: 'Старший юрист',
        broker: 'Ипотечный брокер',
        brokersenior: 'Старший ипотечный брокер',
        lawyerleader: 'Директор юридического департамента',
        lawyerop: 'Юрист ОП',
        lawyeropsenior: 'Старший юрист ОП',
        inspection: 'Контроль качества',
        advglobal: 'Рекламный менеджер',
        hr: 'HR-менеджер',
        hrleader: 'Руководитель HR отдела',
        marketer: 'Маркетолог',
        devdirector: 'Директор по развитию',
        sysadmin: 'Системный администратор',
        financial: 'Финансовый директор',
        security: 'СБ',
        coach: 'Бизнес-тренер',
        coachleader: 'Руководитель отдела обучения',
        consulting: 'Замдиректора по консантинговому направлению',
        sopleader: 'Руководитель офиса продаж',
        sop: 'Менеджер офиса продаж',
    };

    departments = ['Юридический отдел', 'IT отдел', 'Отдел разработки CRM', 'Отдел маркетинга', 'Отдел дизайна', 'Корпоративный отдел', 'HR отдел', 'Фотографы', 'Отдел обучения', 'Служба клиентского сервиса', 'Топ-менеджмент'];

    reMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    rePhone = /^\+?\d{1}?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{2}[-|\s]?\d{2}$/;
}

const vars = new Vars();
export default Object.freeze(vars);
