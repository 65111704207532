class Func {
    numberFormat = (val: number) => {
        return val
            ? val
                  .toString()
                  .replace(/\s+/g, '')
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            : val;
    };

    convertArrayToObject = (array: [], key: string) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
            return {
                ...obj,
                [item[key]]: item,
            };
        }, initialValue);
    };
}

const func = new Func();
export default Object.freeze(func);
