import { lazy, Suspense } from 'react';
import { Navigate, RouterProvider, createBrowserRouter, redirect, useRouteError } from 'react-router-dom';
import { Spin } from 'antd';
import { useAuthStore } from 'stores/authStore';
import { API } from 'api/API';
import { AxiosResponse } from 'axios';
import { IUserAuthData } from 'api/models/IAuth';

async function checkAuth() {
    const { user, setUser } = useAuthStore.getState();
    if (user === null) {
        try {
            const response: AxiosResponse<IUserAuthData> = await API.post('auth/config');
            setUser(response.data);
            return response.data;
        } catch (error) {
            setUser(null);
            return null;
        }
    }
    return user;
}

async function loginLoader() {
    const user = await checkAuth();
    if (user) {
        return redirect('/dashboard');
    }
    return null;
}

async function protectedLoader() {
    const user = await checkAuth();
    if (!user) {
        return redirect('/login');
    }
    return null;
}

export function RootErrorBoundary() {
    const error = useRouteError() as Error;
    return (
        <div>
            <h1>Ошибка</h1>
            <pre>{error.message || JSON.stringify(error)}</pre>
            <button onClick={() => (window.location.href = '/')}>Перезагрузить</button>
        </div>
    );
}

const router = createBrowserRouter([
    {
        path: '/dashboard',
        Component: lazy(() => import('./App')),
        children: [
            {
                path: '',
                Component: lazy(() => import('./containers/Index/Index')),
            },
            {
                path: 'users',
                Component: lazy(() => import('./containers/Users')),
            },
            {
                path: 'offers-crm',
                Component: lazy(() => import('./containers/Offers')),
            },
            {
                path: 'requests',
                Component: lazy(() => import('./containers/Requests')),
            },

            {
                path: 'buildings',
                Component: lazy(() => import('./containers/Buildings')),
            },
            {
                path: '*',
                Component: lazy(() => import('./containers/Pages/NotFound')),
            },
        ],
        loader: protectedLoader,
    },
    {
        path: '/login',
        Component: lazy(() => import('./containers/Pages/SignIn')),
        loader: loginLoader,
    },
    {
        path: '/',
        element: <Navigate to="/dashboard" />,
        loader: protectedLoader,
    },
]);

export default function AppRoutes() {
    return (
        <Suspense>
            <RouterProvider router={router} future={{ v7_startTransition: true }} fallbackElement={<Spin tip="Загрузка" size="large" fullscreen />} />
        </Suspense>
    );
}
