import './App.css';
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

// import * as serviceWorker from './serviceWorker';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import AppRoutes from './router';

library.add(fas);

dayjs.locale('ru');

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <ConfigProvider locale={locale}>
        <AppRoutes />
    </ConfigProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
